<template>
  <div class="page-help content-container flex flex-between" style="align-items: start">

    <div v-if="desIndex" class="content-box html-view"
         v-html="desHtml">
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {find_obj_from_arr_by_id, getQuery} from "../utils/common";
import {CourseModel} from "../model/CourseModel";

export default {
  name: "Introduce",
  computed: {
    ...mapState({})
  },
  data() {
    return {
      find_obj_from_arr_by_id: find_obj_from_arr_by_id,
      tabId: "",
      showBox: 0,
      // 课程详情
      courseInfo: {
        headerTabs:[{},{},{}]
      },
      desIndex: "",
      desHtml:""
    }
  },
  async created() {
    this.desIndex = getQuery("desIndex")
    this.courseInfo = await CourseModel.getOne(getQuery("courseId"))
    this.desHtml=find_obj_from_arr_by_id('id',this.desIndex, this.courseInfo.headerTabs)[1]['text']
  },
  methods: {}
}
</script>

<style scoped lang="less">
@import '../style/app.less';

.page-help {
  margin-top: 20px;
  margin-bottom: 20px;

  .nav-box {
    background-color: #fff;

    .li {
      padding: 10px 40px;
      font-size: 15px;
      cursor: pointer;
      border-right: 3px solid #fff;

      &:hover, &.active {
        color: @main-color;
        font-weight: bold;
        border-color: @main-color;
      }
    }
  }

  .content-box {
    width: 1020px;
    background-color: #fff;
    padding: 20px;

    .nav-bar {
      margin-bottom: 30px;
    }

    .show-box {
      font-size: 14px;

      &.main {
        .title {
          font-weight: bold;
          font-size: 15px;
        }

        .des {
          color: #666;
        }

        .question-list {
          .title {
            margin-bottom: 15px;
          }

          .li {
            cursor: pointer;
            padding-left: 20px;
            color: @main-color;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>
